import { ENV, webGuide } from '../const'

// Set active/current environment
const activeEnvironment = "test";
var firebaseConfig;
const bacchusAPIEndpoint = ENV[activeEnvironment];
const bacchusAPIUploadsPath = ENV[activeEnvironment] + "/tastyimages/";
const webGuideENV = webGuide[activeEnvironment];

if (activeEnvironment === "dev") {
  // Firebase configuration for DEV
  firebaseConfig = {
    apiKey: "AIzaSyB8SyqyqTzhseNcNx_V6Gl-3b77FgGt4uY",
    authDomain: "bacchus-a14fb.firebaseapp.com",
    databaseURL: "https://bacchus-a14fb.firebaseio.com",
    projectId: "bacchus-a14fb",
    storageBucket: "bacchus-a14fb.appspot.com",
    messagingSenderId: "406072562139",
    appId: "1:406072562139:web:dda6d082fdfb654be197fe"
  };
}
else if (activeEnvironment === "test") {
  // Firebase configuration for Testing
  firebaseConfig = {
    apiKey: "AIzaSyAndaYadjEuFlPp2k9Cl8JIR3kYOpPfLzw",
    authDomain: "bacchus-testing.firebaseapp.com",
    projectId: "bacchus-testing",
    storageBucket: "bacchus-testing.appspot.com",
    messagingSenderId: "460418848217",
    appId: "1:460418848217:web:ea8b8ce7a225627da035c8"
  }
}
else if (activeEnvironment === "live") {
  // Firebase configuration for Testing
  firebaseConfig = {
    apiKey: "AIzaSyAmD7yvJXvYM7CxcMXFfXmOcJqql4F6oIo",
    authDomain: "poftigo-live.firebaseapp.com",
    projectId: "poftigo-live",
    storageBucket: "poftigo-live.appspot.com",
    messagingSenderId: "2526457402",
    appId: "1:2526457402:web:a091ad5b212f4470878f03"
  };
}

export { bacchusAPIEndpoint, bacchusAPIUploadsPath, activeEnvironment, firebaseConfig, webGuideENV };