import { Routes, Route } from 'react-router-dom';
import ForgotPassword from './components/ForgotPassword';
import Layout from './components/layout/Layout';
import './App.css';

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<ForgotPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>
    </Routes>
  );
}

export default App;
