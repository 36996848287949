import React, { useState } from 'react';
import { Row, Col, Form, Input, Alert, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import logo from '../assets/img/logo-poftigo-small.png';
import { withFirebase } from '../helpers/Firebase';

const ForgotPassword = (props) => {

    const INITIAL_STATE = {
        email: '',
        error: null,
        success: null
    };

    const [formFields, setFormFields] = useState({ ...INITIAL_STATE })

    const onSubmit = () => {
        console.log(formFields);
        props.firebase
            .doPasswordReset(formFields.email)
            .then(() => {
                setFormFields({ ...INITIAL_STATE });
                setFormFields({ ...formFields, success: "Am trimis un email de resetare a parolei la adresa mentionata" });
            })
            .catch(error => {
                setFormFields({ ...INITIAL_STATE });
                setFormFields({ ...formFields, error: error });
            });
    }

    const onChange = (event) => {
        setFormFields({ ...formFields, [event.target.name]: event.target.value });
    }

    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 16, offset: 4 }} md={{ span: 12, offset: 6 }} lg={{ span: 10, offset: 7 }} xl={{ span: 8, offset: 8 }} xxl={{ span: 6, offset: 9 }}>
                <div className="formBox">
                    <div className="formBoxHeader">

                    </div>
                    <div className="formBoxLogo">
                        <img src={logo} title="Poftigo" alt="Poftigo" />
                    </div>
                    <h1>Recuperare parola</h1>
                    {formFields.success && <Alert type="success" message={formFields.success} />}
                    {formFields.error && <Alert type="error" message="A intervenit o eroare. Verificati adresa de email introdusa." />}
                    <div className="formBoxForm">

                        <Form
                            name="normal_login"
                            onFinish={onSubmit}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: 'Email este camp obligatoriu',
                                    }
                                ]}
                            >
                                <span className="formBoxLabel">Email</span>
                                <Form.Item name="email" noStyle >
                                    <Input size="large" prefix={<MailOutlined className="site-form-item-icon" />}
                                        onChange={onChange}
                                        value={formFields.email}
                                        name="email"
                                        placeholder="" />
                                </Form.Item>

                            </Form.Item>
                            <Form.Item>
                                <Button size="large" type="primary" htmlType="submit" className="formButton">
                                    Trimite
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default withFirebase(ForgotPassword);