import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';


const Layout = () => {
    return (
        <div className="wrapper">
            <div className="wrapperInner">
                <Header />
                <div className="content">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Layout;
