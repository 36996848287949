import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from '../../config'


class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    // instantiate Auth
    this.auth = app.auth();
  }
  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // Send a user a verification email
  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification();

  doReauthenticate = (currentPassword) => {
    var user = this.auth.currentUser;
    var cred = this.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }
}
export default Firebase;