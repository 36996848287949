import React from 'react';
import { Row, Col } from 'antd';

const Footer = () => {
    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 16, offset: 4 }} md={{ span: 12, offset: 6 }} lg={{ span: 10, offset: 7 }} xl={{ span: 8, offset: 8 }} xxl={{ span: 6, offset: 9 }}>
                <div className="footerBlock">
                    Poftigo © 2021
                </div>
            </Col>
        </Row>
    )
}

export default Footer;